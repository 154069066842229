import React from 'react';
import {Employee} from "../components/employee";
import {PageHeader} from "../components/page-header";
import {employees} from "../util/data";

function Team() {
    return (
        <>
            <PageHeader title={"Team"}/>
            <div>
                {employees.map((person, index) => {
                    return <Employee employee={person} index={index}/>
                })}
            </div>
        </>
    );
}

export default Team;