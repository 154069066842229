import React, {ReactElement} from "react";
import {Divider} from "./divider";
import clsx from "clsx";

export type News = {
    header?: string,
    date:Date,
    text?: string,
    textElement?: ReactElement,
    pinned?: Boolean,
    tags?: string[],
    visible?: Boolean
}

export interface NewsEntryProps {
    news: News
}

export function NewsEntry({news}:NewsEntryProps) {
    return <div
       className="block max-w-2xl p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <div className={clsx("flex font-normal text-sm text-gray-700 dark:text-gray-400 pb-4",
            {"justify-between": news.pinned, "justify-end": !news.pinned})}>
            {news.pinned && (<i className="fa-solid fa-thumbtack mr-4 self-center"/>)}
            {news.date.toLocaleDateString()}
        </div>
        {news.header && (<h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{news.header}</h5>)}
        {news.text && ( <p className="font-normal text-gray-700 dark:text-gray-400">{news.text}</p>)}
        {news.textElement && ( <p className="font-normal text-gray-700 dark:text-gray-400">{news.textElement}</p>)}
        {news.tags && news.tags.length > 0 && (
            <>
                <Divider/>
                <div className="px-2">
                    {news.tags.map((tag) => {
                        return <span
                            className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{tag}</span>
                    })}
                </div>
            </>
        )}
    </div>

}