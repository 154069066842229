import clsx from "clsx";
import {ReactElement} from "react";

export type Person = {
    name?: string,
    image?: string,
    secondaryImage?: string,
    title?: string,
    subTitle?: string,
    description?: string,
    element?: ReactElement
}

export interface EmployeeProps {
    employee: Person
    index: number
}

export function Employee({employee, index}:EmployeeProps) {
    return <div className={clsx("px-10 py-10 flex justify-center", {"bg-base-200": index % 2 !== 0})}>
        <div className="max-w-4xl">
            {(employee.element == null) && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
                    {(index % 2 === 0) && (
                        <div>
                            {employee.image && (
                                <div className={clsx({"relative": employee.secondaryImage})}>
                                    {employee.secondaryImage && (
                                        <img className="rounded-3xl max-h-96" src={employee.secondaryImage}/>
                                    )}
                                    {<img className={clsx("rounded-3xl max-h-96 transition ease-in-out duration-1000", {"hover:opacity-0 absolute top-0": employee.secondaryImage})} src={employee.image}/>}
                                </div>
                            )}
                        </div>
                    )}
                    <div>
                        <h2 className="text-2xl pb-1 font-normal">{employee.name}</h2>
                        {(employee.title || employee.subTitle) && (
                            <div className="pb-1 text-accent">
                                <p className="italic font-normal">{employee.title}</p>
                                <p className="italic font-normal">{employee.subTitle}</p>
                            </div>
                        )}
                        <p className="pt-1 font-normal">{employee.description}</p>
                    </div>
                    {(index % 2 !== 0) && (
                        <div>
                            {employee.image && (
                                <div className={"relative"}>
                                    {employee.secondaryImage && (
                                        <img className="rounded-3xl max-h-96" src={employee.secondaryImage}/>
                                    )}
                                    {<img className="rounded-3xl max-h-96 hover:opacity-0 transition ease-in-out duration-1000 absolute top-0" src={employee.image}/>}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {(employee.element != null) && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
                    {(index % 2 === 0) && (
                        <div>
                            {employee.image && (
                                <div className={"relative"}>
                                    {employee.secondaryImage && (
                                        <img className="rounded-3xl max-h-96" src={employee.secondaryImage}/>
                                    )}
                                    {<img className="rounded-3xl max-h-96 hover:opacity-0 transition ease-in-out duration-1000 absolute top-0" src={employee.image}/>}
                                </div>
                            )}
                        </div>
                    )}
                    {employee.element}
                    {(index % 2 !== 0) && (
                        <div>
                            {employee.image && (
                                <div className={"relative"}>
                                    {employee.secondaryImage && (
                                        <img className="rounded-3xl max-h-96" src={employee.secondaryImage}/>
                                    )}
                                    {<img className="rounded-3xl max-h-96 hover:opacity-0 transition ease-in-out duration-1000 absolute top-0" src={employee.image}/>}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    </div>
}