import React from 'react';
import ReactDOM from "react-dom";
import './styles.css';
import 'tw-elements';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Leistungen from "./pages/Leistungen";
import Team from "./pages/Team";
import Kontakt from "./pages/Kontakt";
import Impressum from "./pages/Impressum";
import Aktuelles from "./pages/Aktuelles";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/aktuelles" element={<Aktuelles />} />
                    <Route path="/leistungen" element={<Leistungen />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/kontakt" element={<Kontakt />} />
                    <Route path="/impressum" element={<Impressum />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));