import {MenuItem, Navbar} from "../components/navbar";
import {Footer} from "../components/footer";
import React from "react";
import {Outlet} from "react-router-dom";

const menuItems: MenuItem[] = [
    {
        title: "Home",
        link: "/",
    },
    {
        title: "Aktuelles",
        link: "/aktuelles",
    },
    {
        title: "Leistungen",
        link: "/leistungen"
    },
    {
        title: "Team",
        link: "/team"
    },
    {
        title: "Kontakt",
        link: "/kontakt"
    }
]

const Layout = () => {
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar items={menuItems}/>
            <main className={"flex-grow"}>
                <Outlet />
            </main>
            <Footer/>
        </div>
    );
};

export default Layout;