import React from 'react';
import {PageHeader} from "../components/page-header";
import {newsEntries} from "../util/data";
import {NewsEntry} from "../components/news-entry";

function Aktuelles() {
    let sortedNews = newsEntries.filter((news) => news.visible == null || news.visible).sort((a, b) => {
        if (!a.pinned && b.pinned) {
            return 1;
        } else if (a.pinned && !b.pinned) {
            return -1;
        } else {
            if (a.date < b.date) {
                return 1
            } else if (a.date > b.date) {
                return -1
            } else {
                return 0;
            }
        }

    });
    return (
        <>
            <PageHeader title={"Aktuelles"}/>
            <div className="flex justify-center px-10 py-10">
                <div className="flex flex-col gap-4">
                    {sortedNews.map((news) => {
                        return <NewsEntry news={news}/>
                    })}
                </div>
            </div>
        </>
    );
}

export default Aktuelles;