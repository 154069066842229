import React, {FormEvent, useRef} from 'react';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import emailjs from '@emailjs/browser'
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

export interface ContactFormProps {
    header: string
}

export function ContactForm({header}: ContactFormProps): JSX.Element {
    const [sendStatus, setSendStatus] = React.useState<"SUCCESS" | "ERROR" | "LOADING" | undefined>(undefined);

    const form = useRef<HTMLFormElement>(null);
    const sendEmail = (e: FormEvent) => {
        e.preventDefault();
        if (form.current) {
            setSendStatus("LOADING");
            emailjs.sendForm(
                "service_ni39etl",
                "template_6vb5jsc",
                form.current,
                "L0aSQRm57A9QBdQri"
            ).then(
                result => {
                    console.log(result.text);
                    (document.getElementById('input-name') as HTMLInputElement).value = '';
                    (document.getElementById('input-email') as HTMLInputElement).value = '';
                    (document.getElementById('input-message') as HTMLTextAreaElement).value = '';
                    setSendStatus("SUCCESS");
                },
                error => {
                    console.log(error.text);
                    setSendStatus("ERROR");
                }
            )
        }
    }
    return (
        <form ref={form}>
            <h3>{header}</h3>
            <div className="form-control w-full max-w-md">
                <label className="label">
                    <span className="label-text">Name</span>
                </label>
                <input id="input-name" type="text" className="input input-bordered w-full max-w-md" name={"user_name"} required={true}/>
            </div>
            <div className="form-control w-full max-w-md">
                <label className="label">
                    <span className="label-text">E-Mail Adresse</span>
                </label>
                <input id="input-email" type="email" className="input input-bordered w-full max-w-md" name={"user_email"} required={true}/>
            </div>
            <div className="form-control w-full max-w-md">
                <label className="label">
                    <span className="label-text">Nachricht</span>
                </label>
                <textarea id="input-message" className="textarea textarea-bordered h-24 w-full max-w-md" name={"message"} required={true}/>
            </div>

            <button className={clsx("btn btn-accent text-neutral w-full max-w-md mt-4", {"loading": sendStatus === "LOADING"})} disabled={sendStatus === "LOADING"} onClick={sendEmail}>
                {sendStatus !== "LOADING" && (
                    <>Senden</>
                )}
            </button>
            {sendStatus === "SUCCESS" && (
                <div className="alert alert-success shadow-lg mt-4 w-full max-w-sm flex-row">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        <span>Die Nachricht wurde verschickt!</span>
                    </div>
                    <div className="flex-none">
                        <button className="btn btn-sm bg-transparent border-none" onClick={() => {setSendStatus(undefined)}}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </button>
                    </div>
                </div>
            )}
            {sendStatus === "ERROR" && (
                <div className="alert alert-error shadow-lg mt-4 w-full max-w-md flex-row">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        <span>Fehler beim Senden der Nachricht. Bitte versuchen Sie es erneut.</span>
                    </div>
                    <div className="flex-none">
                        <button className="btn btn-sm bg-transparent border-none" onClick={() => {setSendStatus(undefined)}}>
                            <FontAwesomeIcon icon={faXmark}/>
                        </button>
                    </div>
                </div>
            )}
        </form>
    )
}