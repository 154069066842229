import React from 'react';
import {Link} from "react-router-dom";
import {MenuItem} from "./navbar";

export interface NavDropdownProps {
    items: MenuItem[]
}
const NavDropdown = ({items = []}: NavDropdownProps) => {
    const handleClick = () => {
        const elem = document.activeElement as HTMLElement;
        if(elem){
            elem?.blur();
        }
    };
    return (
        <>
            <div className="dropdown dropdown-end">
                <label tabIndex={0} className="btn btn-ghost btn-square btn-lg">
                    <i className="fa-solid fa-bars"/>
                </label>
                <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-72">
                    {items.map((item, index) => {
                        return <li tabIndex={index} onClick={handleClick}>
                            <Link to={item.link ?? "/"}>
                                {item.title}
                            </Link>
                        </li>
                    })}
                </ul>
            </div>
        </>
    );
};
export default NavDropdown;