import clsx from "clsx";

export interface CarouselProps {
    images: string[]
}

export function Carousel({images = []}: CarouselProps) {
    return <div className="carousel w-full">
        {/*{images.map((src, index) => {*/}
        {/*    return <div id={"slide"+(index+1)} className="carousel-item relative w-full">*/}
        {/*        <img src={src} className="w-full"  alt={"Carousel_Image_"+index+1}/>*/}
        {/*        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">*/}
        {/*            <a href={index === 0? "#slide"+images.length: "#slide"+index} className="btn btn-circle">❮</a>*/}
        {/*            <a href={index === images.length-1? "#slide1": "#slide"+(index+2)} className="btn btn-circle">❯</a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*})}*/}

        <div id="carouselExampleIndicators" className="carousel slide relative" data-bs-ride="carousel">
            <div className="carousel-inner relative w-full overflow-hidden">
                {images.map((image, index) => {
                    return <div className={clsx("carousel-item float-left w-full", {"active": index === 0})}>
                        <img
                            src={image}
                            className="block w-full object-contain"
                            alt={image}
                        />
                    </div>
                })}
            </div>
            <button
                className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"/>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"/>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
}