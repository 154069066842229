import React from 'react';
import {PageHeader} from "../components/page-header";

function Impressum() {
    return (
        <>
            <PageHeader title={"Impressum"} />
            <div className="flex justify-center px-10 py-10 font-normal">
                <div className="max-w-2xl">
                    <div className={"pb-6 font-bold text-3xl"}>
                        Verantwortlich für den Inhalt gemäß § 5 TMG:
                    </div>
                    <div className={"pb-20"}>
                        Berufsausübungsgemeinschaft Dr. Sommer & Dr. Jungesblut GbR<br/>
                        Lohkampstraße 11<br/>
                        22523 Hamburg<br/>
                        Deutschland<br/>
                        <br/>
                        Telefon:  <a href="tel: 49405705321"> +49 40 5705321</a><br/>
                        Telefax: +49 40 5703094<br/>
                        E-Mail: <a href="mailto:info@praxis-lohkamp.de">info@praxis-lohkamp.de</a><br/>
                        Website: <a href="https://www.praxis-lohkamp.de" target="_blank">www.praxis-lohkamp.de</a>
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        BERUFSBEZEICHNUNG:
                    </div>
                    <div className={"pb-20"}>
                        Dr. med. Maria Sommer: Fachärztin für Innere Medizin (verliehen in der Bundesrepublik Deutschland)<br/>
                        <br/>
                        Dr. med. Wibke Jungesblut: Fachärztin für Allgemeinmedizin (verliehen in der Bundesrepublik Deutschland)<br/>
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        ZUSTÄNDIGE KAMMER:
                    </div>
                    <div className={"pb-20"}>
                        Ärztekammer Hamburg<br/>
                        Körperschaft des öffentlichen Rechts<br/>
                        Weidestraße 122b<br/>
                        22083 Hamburg<br/>
                        <a href="https://www.aerztekammer-hamburg.de" target="_blank">www.aerztekammer-hamburg.de</a>
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        ZUSTÄNDIGE AUFSICHTSBEHÖRDE:
                    </div>
                    <div className={"pb-20"}>
                        Kassenärztliche Vereinigung Hamburg<br/>
                        Humboldtstraße 56<br/>
                        22083 Hamburg<br/>
                        <a href="https://www.kvhh.net" target="_blank">www.kvhh.net</a>
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        BERUFSRECHTLICHE REGELUNGEN:
                    </div>
                    <div className={"pb-20"}>
                        Hamburgisches Kammergesetz für die Heilberufe, Berufsordnung, Gebührenordnung für Ärzte (GOÄ),
                        einzusehen unter <a href="https://www.aerztekammer-hamburg.org/rechtsvorschriften.html" target="_blank">https://www.aerztekammer-hamburg.org/rechtsvorschriften.html</a>
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        AUTOR & COPYRIGHT:
                    </div>
                    <div className={"pb-20"}>
                        Dr. med. Wibke Jungesblut & Dr. med. Maria Sommer
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        INTERNETAUFTRITT:
                    </div>
                    <div className={"pb-20"}>
                        Tim Jungesblut
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        BILDNACHWEIS:
                    </div>
                    <div className={"pb-20"}>
                        Privat
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        DATENSCHUTZERKLÄRUNG UND DISCLAIMER:
                    </div>
                    <div className={"pb-20"}>
                        Sehr geehrte Patientin, sehr geehrter Patient,<br/><br/>
                        der Schutz Ihrer personenbezogenen Daten ist uns sehr wichtig.
                        Nach der EU-Datenschutz-Grundverordnung (DSGVO) sind wir verpflichtet,
                        Sie darüber zu informieren, zu welchem Zweck unsere Praxis Daten erhebt, speichert oder weiterleitet.
                        Der Information können Sie auch entnehmen, welche Rechte Sie in puncto Datenschutz haben.
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        1. VERANTWORTLICHKEIT FÜR DIE DATENVERARBEITUNG
                    </div>
                    <div className={"pb-20"}>
                        Verantwortlich für die Datenverarbeitung:<br/>
                        Praxis Lohkamp<br/>
                        Lohkampstraße 11<br/>
                        22523 Hamburg<br/>
                        info@praxis-lohkamp.de<br/>
                        <br/>
                        Datenschutzbeauftragte der Praxis Lohkamp: Dr. med. Wibke Jungesblut
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        2. WIDERSPRUCH GEGEN WERBE-MAILS
                    </div>
                    <div className={"pb-20"}>
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung
                        von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen.
                        Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
                        unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        3. SSL- bzw. TLS-VERSCHLÜSSELUNG
                    </div>
                    <div className={"pb-20"}>
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte,
                        wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden,
                        eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
                        dass die Adresszeile des Browsers von http:// auf https:// wechselt und an dem Schloss-Symbol
                        in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
                        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        4. RECHTLICHE GRUNDLAGEN
                    </div>
                    <div className={"pb-20"}>
                        Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Artikel 9 Absatz 2 lit. h) DSGVO in
                        Verbindung mit Paragraf 22 Absatz 1 Nr. 1 lit. b) Bundesdatenschutzgesetz. Sollten Sie Fragen
                        haben, können Sie sich gern an uns wenden.<br/>
                        <br/>
                        Die Anschrift der für uns zuständigen Aufsichtsbehörde lautet:<br/>
                        Hamburgischer Beauftragter für Datenschutz und Informationsfreiheit (HmbBfDI)<br/>
                        Kurt-Schumacher-Allee 4, 20097 Hamburg, E-Mail: mailbox@datenschutz.hamburg.de
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        5. SERVER-LOG-DATEIEN
                    </div>
                    <div className={"pb-20"}>
                        Der Provider unserer Webseite erhebt und speichert automatisch Informationen in Server-Log-Dateien,
                        die Ihr Browser automatisch an uns übermittelt. Dies sind:<br/>
                        • Browsertyp und Browserversion<br/>
                        • verwendetes Betriebssystem<br/>
                        • IP-Adresse<br/>
                        • Referer-URL<br/>
                        • Hostname des zugreifenden Rechners<br/>
                        • Uhrzeit der Serveranfrage<br/>
                        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von
                        Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        INFORMATIONEN ZUR ONLINE-STREITBEILEGUNG:
                    </div>
                    <div className={"pb-20"}>
                        Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von Streitigkeiten
                        (sog. ‚OS-Plattform‘) geschaffen. Die OS-Plattform dient als Anlaufstelle zur außergerichtlichen
                        Beilegung von Streitigkeiten, die aus Online-Dienstleistungsverträgen erwachsen.
                        Die OS-Plattform wird unter dem folgenden Link erreicht: https://ec.europa.eu/consumers/odr/
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        AUSSERGERICHTLICHE STREITSCHLICHTUNG:
                    </div>
                    <div className={"pb-20"}>
                        Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nach
                        dem ‚Gesetz über die alternative Streitbeilegung in Verbrauchersachen (VSBG)‘ sind wir nicht
                        bereit und nicht verpflichtet.
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        HAFTUNGSAUSSCHLUSS - Haftung für Inhalte:
                    </div>
                    <div className={"pb-20"}>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
                        jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                        allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
                        dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        HAFTUNGSAUSSCHLUSS - Haftung für Links:
                    </div>
                    <div className={"pb-20"}>
                        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
                        haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                        Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                        erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                        konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                    </div>
                    <div className={"pb-6 font-bold text-2xl"}>
                        URHEBERRECHT:
                    </div>
                    <div className={"pb-20"}>
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                        nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                        erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
                        als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                        werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                        werden wir derartige Inhalte umgehend entfernen.
                    </div>
                </div>
            </div>
        </>
    );
}

export default Impressum;