import React from 'react';
import {PageHeader} from "../components/page-header";
import {services} from "../util/data";
import {ServiceElement} from "../components/service-element";

function Leistungen() {
    return (
        <>
            <PageHeader title={"Leistungen"} />
            <div className="flex align-middle px-10 py-10 flex-col items-center">
                <div className={"mb-6 font-normal"}>
                    Wir verstehen uns als erste AnsprechpartnerInnen in allen Gesundheitsfragen.<br/>
                    Damit wir Sie umfassend beraten und behandeln können, stehen bei uns folgende Leistungen zur Verfügung:
                </div>
                {/*<div className="chat chat-start py-10 w-full">*/}
                {/*    <div className="chat-bubble chat-bubble-accent font-normal">"alles was ein Hausarzt so macht..."</div>*/}
                {/*    <div className="chat-footer font-normal"> - U. Kraft</div>*/}
                {/*</div>*/}
                <div className={"grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4"}>
                    {services.map((service) => {
                        return <ServiceElement serviceGroup={service}/>
                    })}
                </div>
            </div>
        </>
    );
}

export default Leistungen;