import {ServiceGroup} from "../components/accordion";
import {Person} from "../components/employee";
import React from "react";
import {News} from "../components/news-entry";

export const services: ServiceGroup[] = [
    {
        groupTitle: "Diagnostik",
        icon: "fa-stethoscope",
        services: [
            {
                serviceTitle: "Labor"
            },
            {
                serviceTitle: "Ruhe-EKG"
            },
            {
                serviceTitle: "körperliche Untersuchung"
            },
            {
                serviceTitle: "Blutdruck- und Langzeitblutdruckmessung"
            },
            {
                serviceTitle: "Sonographie",
                subServices: [
                    {
                        serviceTitle: "Abdomen"
                    },
                    {
                        serviceTitle: "Schilddrüse"
                    },
                    {
                        serviceTitle: "Screening der Bauchaorta"
                    },
                ]
            },
            {
                serviceTitle: "Lungenfunktionsprüfung (Spirometrie)"
            },
        ]
    },
    {
        groupTitle: "Gesundheitsvorsorge",
        icon: "fa-briefcase-medical",
        services: [
            {
                serviceTitle: "Check up / check up <35"
            },
            {
                serviceTitle: "Jugendschutzuntersuchungen"
            },
            {
                serviceTitle: "Hautkrebsscreening"
            },
            {
                serviceTitle: "Impfungen"
            },
            {
                serviceTitle: "Präoperative Diagnostik"
            },
            {
                serviceTitle: "Chroniker Programme, wie z.B.\n" +
                    "DMP KHK, DMP Diabetes mellitus, DMP COPD / Asthma"
            },
            {
                serviceTitle: "Krebsvorsorge für Männer"
            },
            {
                serviceTitle: "Beratung zur Patientenverfügung"
            },
        ]
    },
    {
        groupTitle: "Therapie",
        icon: "fa-bandage",
        services: [
            {
                serviceTitle: "Psychosomatische Grundversorgung"
            },
            {
                serviceTitle: "Hausbesuche"
            },
            {
                serviceTitle: "Versorgung kleiner Wunden"
            },
            {
                serviceTitle: "Geriatrische Basisdiagnostik"
            },
            {
                serviceTitle: "Hausärztliche palliativmedizinische Behandlung"
            },
            {
                serviceTitle: "HZV (Hausarztzentrierte Versorgung)"
            },

        ]
    },
    {
        groupTitle: "Individuelle Gesundheitsleistungen (IGeL) ",
        icon: "fa-syringe",
        services: [
            {
                serviceTitle: "Reisemedizinische Beratung"
            },
            {
                serviceTitle: "Reiseimpfungen"
            },
            {
                serviceTitle: "Ärztliche Atteste"
            },
            {
                serviceTitle: "Tauglichkeitsuntersuchungen"
            },
            {
                serviceTitle: "Erweiterte Laboruntersuchungen, wie z.B. Vitaminbestimmungen und erweiterter Check"
            },
            // {
            //     serviceTitle: "Mikrobiomanalyse"
            // },
        ]
    }
]

export const carouselImages: string[] = [
    "/images/home/1.JPG",
    "/images/home/DSC_0287.JPG",
    "/images/home/DSC_0344.JPG",
    "/images/home/DSC_0548.JPG",
    "/images/home/DSC_0937.JPG",
    "/pictures/neu/DSC_0058.JPG",
]

export const employees: Person[]= [
    {
        description: "Wir legen als Hausärzte viel Wert auf den persönlichen Kontakt und die langfristige Behandlung und Begleitung unserer Patienten. Mit unserem Team aus zwei Ärztinnen und einem Team aus 5 MFAs wollen wir uns Zeit für Sie und Ihre Erkrankungen nehmen.",
        image: "/images/DSC_0259.JPG",
    },
    {
        name: "Dr. W. Jungesblut",
        image: "/images/DSC_0742.JPG",
        secondaryImage: "/images/DSC_0405.JPG",
        title: "Fachärztin für Allgemeinmedizin" ,
        description: "Frau Jungesblut hat ihre Ausbildung zur Fachärztin für Allgemeinmedizin in der chirurgischen und internistischen Abteilung des Albertinen-Krankenhauses absolviert. Es folgten dann weitere Stationen in einer großen allgemeinmedizinischen Praxis in Eimsbüttel und Harvestehude jeweils mit ernährungsmedizinischem Schwerpunkt. \n" +
            "Seit Januar 2023 ergänzt sie das Team von Dr. Kraft und Dr. Sommer.\n"
    },
    {
        name: "Dr. M. Sommer",
        image: "/images/DSC_0411.JPG",
        secondaryImage: "/images/DSC_0614.jpeg",
        title: "Fachärztin für Innere Medizin" ,
        description: "Frau Sommer hat Ihre Ausbildung zur Internistin größtenteils im Städtischen Krankenhaus Lüneburg absolviert, wo sie ihr Interesse für die Sonographie entdeckt hat. Des Weiteren war sie in einer internistischen Hausarztpraxis in Harvestehude und im Krankenhaus Gross-Sand mit geriatrischem Tätigkeitsschwerpunkt angestellt.\n" +
            "Bis zur Anstellung in der Praxis Dr. Kraft im September 2022 war sie mehrere Jahre als hausärztliche Internistin in einer Praxis in Wandsbek/Dulsberg tätig.\n"
    },
    // {
    //     name: "Dr. U. Kraft",
    //     image: "/images/DSC_0126.JPG",
    //     secondaryImage: "/images/DSC_0133.JPG",
    //     title: "Facharzt für Innere Medizin und Allgemeinmedizin" ,
    //     subTitle: "Zusatzbezeichnung Naturheilverfahren",
    //     description: "Herr Kraft hat im Januar 2002 die Praxis am Eidelstedter Platz 23a übernommen und ist damit schon seit über 20 Jahren in Eidelstedt als Hausarzt tätig. Seine Weiterbildung zum Facharzt für Innere Medizin und Facharzt für Allgemeinmedizin hat er am Krankenhaus der DRK-Schwesternschaft in Hamburg-Rissen (jetzt: Asklepios Westklinikum) absolviert. Außerdem hat er die Grundausbildung Akupunktur bei der DÄGFA erworben."
    // },
    // {
    //     name: "Frau Lutter",
    //     image: "https://picsum.photos/536/354",
    //     title: "Medizinische Fachangestellte",
    //     description: "Sie ist seit dem Beginn der Praxis dabei und kennt daher fast jeden Patienten persönlich. Keine Blutentnahme ist ihr zu schwer und kein Fall zu kompliziert."
    // },
    // {
    //     name: "Frau Gunkel",
    //     image: "https://picsum.photos/536/354",
    //     title: "Medizinische Fachangestellte",
    //     description: "In dieser Praxis seit 2009 (??) hat sie für die Patienten stets einen passenden Termin und einen guten Rat. Zudem ist sie für die Abrechnung und die Organisation der Praxis unentbehrlich."
    // },
    // {
    //     name: "Frau Volkmann",
    //     image: "https://picsum.photos/536/354",
    //     title: "Medizinische Fachangestellte",
    //     description: "Sie ist seit 2020 festes und zuverlässiges Mitglied unserer Praxis, vorher war sie bereits in Eidelstedt bei Dr. Reincke tätig. Seit 2022 ist sie eine zusätzliche Verstärkung als VERAH (Versorgungsassistentin in der Hausarztpraxis) und NäPa (nichtärztliche Praxisassistentin), insbesondere für die Hausbesuche."
    // },
    // {
    //     name: "Frau Fromm",
    //     image: "https://picsum.photos/536/354",
    //     title: "Auszubildende zur medizinischen Fachangestellten",
    //     description: "Sie ist bei uns in Ausbildung zur MFA, hat sich  gut eingearbeitet und ergänzt unser Team durch neue Ideen und Vorschläge. Ihren Abschluss wird sie voraussichtlich 2024 machen."
    // }
    {
        element: <div className={"font-normal"}>
            Unser <b>nicht-ärztliches Praxisteam</b> besteht aus<br/>
            <br/>
            Frau Volkmann mit einem zusätzlichen Schwerpunkt als VERAH (Versorgungsassistentin in der Hausarztpraxis)
            und NäPa (nichtärztliche Praxisassistentin) in der Durchführung von Hausbesuchen,<br/>
            <br/>
            Frau Gunkel und Frau Sobczak mit Schwerpunkt der Abrechnung,<br/>
            <br/>
            Frau Driouch-Ghabri unterstützt uns als ausgebildete Krankenschwester besonders im Labor.<br/>
            Frau Fromm hat Anfang des Jahres 2024 erfolgreich ihre Ausbildung als medizinische Fachangestellte beendet und ist nun bei uns als MFA angestellt.<br/>
            <br/>
            Frau Lutter ist seit September 2023 im Ruhestand. Wir bedanken uns für die tolle, langjährige Zusammenarbeit
            und sagen „Tschüß, Frau Lutter. Alles Gute!“.<br/>
            <br/>
            Einige unserer Mitarbeiterinnen begleiten die Praxis schon seit langem.<br/>
            In den letzten Monaten hat sich das Gesicht der Praxis Dr. Kraft weiterentwickelt. Als Konstante bleibt der
            freundliche und vertrauensvolle Umgang mit Ihnen als Patientin und als Patient.
        </div>
    },
]

export const newsEntries: News[]= [
    {
        textElement: <div>
            Liebe PatientInnen, <br/>
            <br/>
            aufgrund der aktuellen personellen Situation haben sich unsere Sprechzeiten am Vormittag vorübergehend
            wie folgt geändert:<br/>
            Montag bis Freitag: 8:30 - 12:30 Uhr
        </div>,
        date: new Date(2023, 9, 9),
        tags: ["sprechzeiten"]
    },
    {
        textElement: <div>
            Liebe PatientInnen, <br/>
            <br/>
            ab Oktober bieten wir wie gewohnt die saisonale Impfung gegen Influenza (Grippe) an.<br/>
            Ebenso ist laut <a className={"font-medium text-blue-600 dark:text-blue-500 hover:underline"} href="https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Stellungnahme-COVID-19-Varianten-adaptierte-Impfstoffe.html" target="_blank">Stiko</a> die Auffrischungsimpfung gegen SarsCoV2 (Corona) für PatientInnen ab 60 Jahren empfohlen.<br/>
            Sie können gerne täglich zwischen 10 und 12 Uhr ohne Termin zum Impfen kommen.
        </div>,
        tags: ["impfung", "influenza", "corona"],
        date: new Date(2023, 9, 9),
        visible: false
    },
    {
        textElement: <div>
            Liebe PatientInnen, <br/>
            <br/>
            ab Oktober bieten wir wie gewohnt die saisonale Impfung gegen Influenza (Grippe) an.<br/>
            Ebenso ist laut <a className={"font-medium text-blue-600 dark:text-blue-500 hover:underline"} href="https://www.rki.de/DE/Content/Kommissionen/STIKO/Empfehlungen/Stellungnahme-COVID-19-Varianten-adaptierte-Impfstoffe.html" target="_blank">Stiko</a> die Auffrischungsimpfung gegen SarsCoV2 (Corona) für PatientInnen ab 60 Jahren empfohlen.<br/>
            Sie können gerne Dienstags und Freitags zwischen 9:30 und 12 Uhr ohne Termin zum Impfen kommen.
        </div>,
        tags: ["impfung", "influenza", "corona"],
        date: new Date(2023, 10, 1),
    },
    {
        textElement: <div>
            Liebe PatientInnen, <br/>
            <br/>
            aus betriebsbedingten Gründen bleibt die Praxis am 13.11.23 ab 11 Uhr geschlossen.
        </div>,
        date: new Date(2023, 10, 1),
        tags: ["öffnungszeiten"],
        pinned: false
    },
    {
        textElement: <div>
            Öffnungszeiten Ende des Jahres<br/>
            <br/>
            Liebe PatientInnen, <br/>
            <br/>
            wir haben vom 27. - 29.12.2023 täglich eine Notfall-Sprechstunde von 9 - 12 Uhr.
            Ab dem 2.Januar sind wir wieder wie gewohnt von 08:30 - 13 Uhr für Sie da.<br/>
            <br/>
            Wir wünschen Ihnen frohe Festtage und ein gesundes neues Jahr!
        </div>,
        date: new Date(2023, 11, 13),
        tags: ["öffnungszeiten"],
        pinned: false
    },
    {
        textElement: <div>
            Liebe PatientInnen, <br/>
            <br/>
            ab dem 01.01.2024 haben wir folgende Sprechzeiten:<br/>
            <br/>
            Akutsprechstunde:<br/>
            Montag - Freitag:  8:30 - 9:30 Uhr<br/>
            <br/>
            Terminsprechstunde:<br/>
            Montag: 9:30 - 13:00 Uhr<br/>
            Dienstag: 9:30 - 13:00 Uhr und 14:30 - 17:30 Uhr<br/>
            Mittwoch: 9:30 - 13:00 Uhr<br/>
            Donnerstag: 9:30 - 13:00 Uhr und 14:00 - 18:00 Uhr<br/>
            Freitag: 9:30 - 13:00 Uhr<br/>
        </div>,
        date: new Date(2024, 0, 30),
        tags: ["öffnungszeiten"],
        pinned: false
    },
    {
        textElement: <div>
            Liebe PatientInnen, <br/>
            <br/>
            aus betriebsbedingten Gründen bleibt die Praxis am 22.03.24 geschlossen.
        </div>,
        date: new Date(2024, 2, 12),
        tags: ["öffnungszeiten"],
        pinned: false
    },
    {
        textElement: <div>
            In Hamburg sagt man "Tschüss", <br/>
            <br/>
            nach fast 25 Jahren hausärztlicher Tätigkeit, davon über 21 Jahre am Eidelstedter Platz, werde ich zu Ende Juni 2024 aus der Gemeinschaftspraxis ausscheiden.<br/>
            <br/>
            Ich freue mich, dass es gelungen ist, meine Niederlassung an 2 junge, motivierte und engagierte Kolleginnen weiterzugeben, die die Hausarztpraxis mit neuen Ideen fortführen werden und kann mich daher mit einem guten Gefühl in den Vor-Ruhestand zurückziehen.<br/>
            <br/>
            Bleiben Sie gesund und meinen Nachfolgerinnen und dem verjüngten Praxisteam gewogen!<br/>
            <br/>
            Ihr langjähriger Hausarzt Dr. Kraft
        </div>,
        date: new Date(2024, 3, 14),
        tags: ["tschüss", "abschied"],
        pinned: false
    },
    {
        textElement: <div>
            Liebe Patientinnen und Patienten, <br/>
            <br/>
            Wir machen Urlaub.<br/>
            In der Zeit vom 20.-24.05.2024 bleibt unsere Praxis geschlossen.<br/>
            Unsere Vertretung für Notfälle übernehmen in dieser Zeit:<br/>
            <br/>
            Dr. Polzin<br/>
            Holsteiner Chaussee 10<br/>
            22523 Hamburg<br/>
            <br/>
            Dr. Jacob / Dr. Schäfer<br/>
            Pinneberger Chaussee 137<br/>
            22523 Hamburg<br/>
            <br/>
            Dr. Melderis<br/>
            Franzosenkoppel 111<br/>
            22547 Hamburg<br/>
            <br/>
            In dringenden Notfällen rufen Sie bitte den Notdienst der kassenärztlichen Vereinigung <b>116117</b> oder <b>112</b> an.<br/>
            <br/>
            Wir wünschen Ihnen frohe Pfingsten. Ab dem 27.05.2024 sind wir wieder zu unseren gewohnten Öffnungszeiten für Sie da.<br/>
            <br/>
            Herzlichst<br/>
            Ihr Team Lohkamp
        </div>,
        date: new Date(2024, 4, 13),
        tags: ["urlaub", "vertretung"],
        pinned: false
    },
    {
        textElement: <div>
            Liebe PatientInnen, <br/>
            <br/>
            aufgrund der Sommerferien fallen folgende Nachmittagssprechstunden aus:<br/>
            Dienstag    - 23.07.2024<br/>
            Donnerstag  - 01.08.2024<br/>
            Dienstag    - 13.08.2024<br/>
            Dienstag    - 20.08.2024<br/>
            Dienstag    - 27.08.2024<br/>
            <br/>
            Wir danken für Ihr Verständnis und wünschen Ihnen einen gesunden Sommer!
        </div>,
        date: new Date(2024, 6, 22),
        tags: ["öffnungszeiten", "sommer"],
        pinned: false
    },
]