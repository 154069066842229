import React from "react";
import clsx from "clsx";

export type ServiceGroup = {
    groupTitle: string,
    services: Service[],
    icon: string,
}

type Service = {
    serviceTitle: string,
    subServices?: Service[]
}

interface ServiceElementProps {
    serviceGroup: ServiceGroup
}



export function ServiceElement({serviceGroup}: ServiceElementProps) {
    const [collapsed, setCollapsed] = React.useState<boolean>(true);

    function toggleCollapsed() {
        setCollapsed(!collapsed);
    }

    return <div className="flex justify-start flex-col">
        <div className={"h-[300px] w-[300px]"}>
            <button className="btn btn-primary btn-outline h-full w-full flex-col gap-8 font-normal" onClick={toggleCollapsed}>
                <i className= {"fa-solid fa-4x " + serviceGroup.icon}/>
                {serviceGroup.groupTitle}
            </button>
        </div>
        <div className={clsx("service-collapse max-w-[300px] px-4 py-2 overflow-hidden h-full", {"h-[0px] py-0": collapsed})}>
            <ul className="dash border-b-2 pb-2 border-primary">
                {serviceGroup.services.map((service) => {
                    return <>
                        {(!service.subServices || service.subServices.length === 0) && (
                            <li className={"font-normal"}>{service.serviceTitle}</li>
                        )}
                        {(service.subServices && service.subServices.length > 0) && (
                            <>
                                <li className={"font-normal"}>{service.serviceTitle}:</li>
                                <ul className="dash pl-5">
                                    {service.subServices.map((subService) => {
                                        return  <li className={"font-normal"}>{subService.serviceTitle}</li>
                                    })}
                                </ul>
                            </>

                        )}
                    </>

                })}
            </ul>
        </div>
    </div>
}