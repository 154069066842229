import {Carousel} from "../components/carousel";
import React from "react";
import {PageHeader} from "../components/page-header";
import {carouselImages} from "../util/data";

function Home() {
    return (
        <>
            <PageHeader title={"Home"} />
            {/*<div className={"flex-grow flex justify-center py-20"}>*/}
            <div className={"flex justify-center px-10 py-10"}>
                <div className="flex flex-col items-center">
                    <div className="max-w-4xl pb-10 flex">
                        <Carousel images={carouselImages}/>
                    </div>
                    <div className={"font-normal"}>
                        Herzlich Willkommen auf der neuen Internetpräsenz der Gemeinschaftspraxis von Dr. Sommer und Dr. Jungesblut!
                    </div>
                </div>
            </div>
        </>

    );
}

export default Home;