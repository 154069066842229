import React from 'react';
import {Link} from "react-router-dom";

export function Footer(): JSX.Element {
    return (
        <footer className="footer items-center py-4 px-20 bg-secondary text-secondary-content">
            <div className="items-center">
                <div>
                    <i className="fa-solid fa-phone mr-4"/>
                    <a href="tel: 49405705321"> +49 40 5705321</a>
                </div>
                <div>
                    <i className="fa-solid fa-envelope mr-4"/>
                    <a href="mailto:info@praxis-lohkamp.de">info@praxis-lohkamp.de</a>
                </div>
                <div>
                    <i className="fa-solid fa-location-dot mr-4"/>
                    <a href="https://www.google.com/maps/place/Lohkampstra%C3%9Fe+11,+22523+Hamburg" target="_blank">Lohkampstraße 11, 22523 Hamburg</a>
                </div>
                <div>
                    <i className="fa-solid fa-clipboard mr-4"/>
                    <Link className={"font-normal hover:underline"} to={"/impressum"}>
                        Impressum
                    </Link>
                </div>

            </div>
            <div className="items-center md:justify-self-end">
                <div className={"grid grid-cols-2 gap-2"}>
                    <div className={"col-span-2"}>Öffnungszeiten</div>
                    <div className={"col-span-2 pt-4"}>Akutsprechstunde</div>
                    <div>Montag - Freitag:</div>
                    <div className={"text-end"}>8:30 - 9:30 Uhr</div>
                    <div className={"col-span-2 pt-4"}>Terminsprechstunde</div>
                    <div>Montag:</div>
                    <div className={"text-end"}>9:30 - 13:00 Uhr</div>
                    <div>Dienstag:</div>
                    <div className={"text-end"}>9:30 - 13:00 Uhr<br/>15:00 - 18:00 Uhr</div>
                    <div>Mittwoch:</div>
                    <div className={"text-end"}>9:30 - 13:00 Uhr</div>
                    <div>Donnerstag:</div>
                    <div className={"text-end"}>9:30 - 13:00 Uhr<br/>15:00 - 18:00 Uhr</div>
                    <div>Freitag:</div>
                    <div className={"text-end"}>9:30 - 13:00 Uhr</div>
                </div>
            </div>
        </footer>
    )
}