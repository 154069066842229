import {Link} from "react-router-dom";
import React from 'react';
import NavDropdown from "./nav-dropdown";

export type MenuItem = {
    title: string,
    link?: string,
}

export interface NavbarProps {
    items: MenuItem[]
}

export function Navbar({
    items = []
}: NavbarProps): JSX.Element {
    return (
        <div className="navbar bg-base-100 px-10 items-center text-primary">
            <div className="flex-1">
                <Link to={"/"}>
                    <img src="/logos/02_SJ/SVG-SJ/Logo-SJ-BREIT.svg" alt="Logo_Praxis_Lohkamp" className="w-[350px]"/>
                </Link>
            </div>
            <div className="flex-none hidden lg:block">
                <ul className="menu menu-horizontal px-1">
                    {items.map((item, index) => {
                        return <li tabIndex={index}>
                            <Link className={"font-normal"} to={item.link ?? "/"}>
                                {item.title}
                            </Link>
                        </li>
                    })}
                </ul>
            </div>
            <div className="lg:hidden">
                <NavDropdown items={items}/>
            </div>
        </div>

    )
}